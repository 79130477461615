@charset "UTF-8";

@import "../common/variables";
@import "../common/layout";
@import "../common/functions";


//---------------------------------------------------------------
// フッター全体
//---------------------------------------------------------------
.l-renewal-content {
  #footer {
    background-color: $bg-base-footer;
    @extend .l-w-100per;
    font-size: $font-size-m;

    #footer-sns-links {
      text-align: center;

      a {
        display: inline-block;
        width: 38px;
      }
    }

    #footer-map {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      //a {
      //  padding: 8px 16px;
      //}
    }

    small.copyright {
      display: block;
      text-align: center;
      font-size: $font-size-m;
      letter-spacing: 0;
    }
  }
} // .l-renewal-content
